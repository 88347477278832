//@ts-nocheck
import { Center, Flex, Loader, SegmentedControl, Tabs } from "@mantine/core";
import React, { useEffect, useState } from "react";
import useGetReduxState from "../useGetReduxState";
import { setTimeStamp } from "../store/Dispatchers/App";
import { PiSpeakerSimpleHighFill, PiSpeakerSimpleXFill } from "react-icons/pi";
import moment from "moment";

const TextWithTranscript = ({
  start,
  end,
  transcript,
  speaker,
  handleClick,
}) => {
  return (
    <div
      className="list-item"
      style={{ alignItems: "flex-start" }}
      onClick={() => {
        setTimeStamp(start, end);
        handleClick();
      }}
    >
      <p style={{ whiteSpace: "normal", overflow: "auto" }}>
        <span>{start}</span> <br /> <b>{speaker} : </b>
        {transcript}
      </p>
    </div>
  );
};

const PersonWithTimeStamps = ({ start, person, handleClick }) => {
  return (
    <div
      className="list-item"
      style={{ alignItems: "flex-start" }}
      onClick={() => {
        console.log("======>", start);
        let min = parseInt(start.split(":")[0]);
        let sec = parseInt(start.split(":")[0]);
        setTimeStamp(min * 60 + sec, -1);
        handleClick();
      }}
    >
      <span>{start}</span>
      {/* <span>{moment(start, "m:s").format("mm:ss")}</span> */}
      <p style={{ whiteSpace: "normal", overflow: "auto" }}>{person}</p>
    </div>
  );
};

const content = [
  { speaker: "Lawyer", time: "00:01" },
  { speaker: "Judge1", time: "00:16" },
  { speaker: "Lawyer", time: "00:10" },
  { speaker: "Judge1", time: "00:33" },
  { speaker: "Lawyer", time: "00:40" },
  { speaker: "Judge1", time: "00:41" },
  { speaker: "Lawyer", time: "00:55" },
  { speaker: "Judge1", time: "00:57" },
  { speaker: "Lawyer", time: "01:00" },
  { speaker: "Judge1", time: "01:01" },
  { speaker: "Lawyer", time: "01:03" },
  { speaker: "Lawyer", time: "01:06" },
  { speaker: "Judge1", time: "01:07" },
  { speaker: "Lawyer", time: "01:47" },
  { speaker: "Judge1", time: "01:48" },
  { speaker: "Lawyer", time: "03:40" },
  { speaker: "Judge1", time: "03:48" },
  { speaker: "Lawyer", time: "03:49" },
  { speaker: "Judge1", time: "04:14" },
  { speaker: "Lawyer", time: "05:09" },
  { speaker: "Judge1", time: "05:11" },
  { speaker: "Lawyer", time: "05:19" },
  { speaker: "Judge1", time: "05:20" },
  { speaker: "Lawyer", time: "05:23" },
  { speaker: "Judge1", time: "05:25" },
  { speaker: "Lawyer", time: "07:01" },
  { speaker: "Judge1", time: "07:19" },
  { speaker: "Lawyer", time: "07:20" },
  { speaker: "Judge2", time: "08:15" },
  { speaker: "Judge1", time: "09:09" },
  { speaker: "Lawyer", time: "09:10" },
  { speaker: "Judge2", time: "09:38" },
  { speaker: "Lawyer", time: "10:24" },
  { speaker: "Judge2", time: "10:33" },
  { speaker: "Lawyer", time: "11:17" },
  { speaker: "Judge2", time: "11:34" },
  { speaker: "Lawyer", time: "11:35" },
  { speaker: "Judge2", time: "11:39" },
  { speaker: "Lawyer", time: "11:40" },
  { speaker: "Judge1", time: "11:48" },
  { speaker: "Lawyer", time: "13:54" },
  { speaker: "Judge1", time: "14:18" },
  { speaker: "Lawyer", time: "14:21" },
  { speaker: "Judge1", time: "14:43" },
  { speaker: "Lawyer", time: "15:07" },
];

const content2 = [
  { speaker: "Mallikarjun Kharge", time: "0:18" },
  { speaker: "Binoy Viswam", time: "2:49" },
];

const content3 = [
  { speaker: "Adhir Ranjan Chowdhury", time: "0:36" },
  { speaker: "Mansukh Mandaviya", time: "2:26" },
  { speaker: "B Venkata Satyavathi", time: "4:47" },
  { speaker: "Dr Bharti Pawar", time: "5:23" },
];

const content4 = [{ speaker: "PM Narendra Modi", time: "0:35" }];

const content5 = [
  { speaker: "Aman Gupta", time: "2:00" },

  { speaker: "Vineeta Singh", time: "2:14" },

  { speaker: "Anupam Mittal", time: "2:29" },

  { speaker: "Peyush Bansal", time: "5:23" },

  { speaker: "Namita Thapar", time: "5:33" },
];

const content6 = [
  {
    speaker: "N. P. Singh",
    time: "0:16",
  },
];

const Transcript = ({ close }) => {
  const data = useGetReduxState().data.trainData;
  const [value, setValue] = useState("en");
  const video = useGetReduxState().data.video;
  const appId = useGetReduxState().data.trainData.appId;
  const [playing, setPlaying] = useState(false);

  const getContent = () => {
    // if (appId === "7ac2bfb9-a424-406d-b37e-a488aa83d7d7")
    return (
      <div>
        {content.map((cd) => (
          <PersonWithTimeStamps
            start={cd.time}
            person={cd.speaker}
            handleClick={close}
          />
        ))}
      </div>
    );

    // if (video.includes("09-02-2023"))
    //   return (
    //     <div>
    //       {content2.map((cd) => (
    //         <PersonWithTimeStamps
    //           start={cd.time}
    //           person={cd.speaker}
    //           handleClick={close}
    //         />
    //       ))}
    //     </div>
    //   );

    // if (video.includes("01-04-2022"))
    //   return (
    //     <div>
    //       {content3.map((cd) => (
    //         <PersonWithTimeStamps
    //           start={cd.time}
    //           person={cd.speaker}
    //           handleClick={close}
    //         />
    //       ))}
    //     </div>
    //   );

    // if (video.includes("PM Modi's Mann Ki Baat with the Nation, June 2023"))
    //   return (
    //     <div>
    //       {content4.map((cd) => (
    //         <PersonWithTimeStamps
    //           start={cd.time}
    //           person={cd.speaker}
    //           handleClick={close}
    //         />
    //       ))}
    //     </div>
    //   );

    // if (video.includes("Shark Tank India  Recode  Season 2  Full Pitch"))
    //   return (
    //     <div>
    //       {content5.map((cd) => (
    //         <PersonWithTimeStamps
    //           start={cd.time}
    //           person={cd.speaker}
    //           handleClick={close}
    //         />
    //       ))}
    //     </div>
    //   );

    // if (
    //   video.includes("Mr. N. P. Singh talks about Sony Pictures Networks India")
    // )
    //   return (
    //     <div>
    //       {content6.map((cd) => (
    //         <PersonWithTimeStamps
    //           start={cd.time}
    //           person={cd.speaker}
    //           handleClick={close}
    //         />
    //       ))}
    //     </div>
    //   );

    return (
      <Center mt={"xl"}>
        <Loader />
      </Center>
    );
  };

  const togglePlaying = () => {
    let audioElem = document.getElementById("audio-element");

    if (playing) audioElem.pause();
    else audioElem.play();
    setPlaying((prev) => !prev);
  };

  useEffect(() => {
    let audioElem = document.getElementById("audio-element");
    // @ts-ignore
    audioElem.pause();
    // @ts-ignore
    audioElem.src = data.summary[value]?.audio;
  }, [value]);

  const getSummary = (text) => {
    return (
      text

        // .replaceAll(
        //   "The summary should be no more than 200 words. Please summarize the following text:",
        //   ""
        // )
        // .replaceAll(
        //   "सारांश 200 शब्दों से अधिक नहीं होना चाहिए। कृपया निम्नलिखित पाठ का सारांश देंः",
        //   ""
        // )
        // .replaceAll(
        //   "सारांश 200 शब्दांपेक्षा जास्त नसावा. . कृपया खालील मजकुराचा सारांश द्याः",
        //   ""
        // )
        // .replaceAll(
        //   "સારાંશ 200 શબ્દોથી વધુ ન હોવો જોઈએ. કૃપા કરીને નીચેના લખાણનો સારાંશ આપોઃ ",
        //   ""
        // )
        // .replaceAll(
        //   "സംഗ്രഹം 200 വാക്ക കുകളിൽ കവിയാൻ പാടില്ല. ദയവായി താഴെപ്പറയുന്ന വാചകം സംഗ്രഹിക്കുകഃ ",
        //   ""
        // )
        // .replaceAll(
        //   "சுருக்கம் 200 வார்த்தைகளுக்கு மிகாமல் இருக்க வேண்டும். தயவுசெய்து பின்வரும் உரையை சுருக்கம மாகக் கூறுங்கள்ஃ",
        //   ""
        // )
        .replaceAll("\\n", "")
    );
  };

  return (
    <Tabs defaultValue="gallery">
      <Tabs.List>
        <Tabs.Tab value="gallery" w={"25%"}>
          With Timestamps
        </Tabs.Tab>
        <Tabs.Tab value="messages" w={"25%"}>
          Free Text
        </Tabs.Tab>
        <Tabs.Tab value="messages3" w={"25%"}>
          Summary
        </Tabs.Tab>
        <Tabs.Tab value="messages2" w={"25%"}>
          Classified Content
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="gallery" pt="xs">
        {console.log("ANKUR", data.transcriptionTime)}
        {data.transcriptionTime.map((result) => (
          <TextWithTranscript
            start={result.Start}
            end={result.End}
            transcript={result.Text}
            speaker={result.Speaker}
            handleClick={close}
          />
        ))}
      </Tabs.Panel>
      <Tabs.Panel value="messages" pt="xs">
        <div>{data.transcriptionFreeEn}</div>
      </Tabs.Panel>
      <Tabs.Panel value="messages2" pt="xs">
        {getContent()}
      </Tabs.Panel>
      <Tabs.Panel value="messages3" pt="xs">
        <Flex mb={"lg"} align={"center"} justify={"space-evenly"}>
          <SegmentedControl
            value={value}
            onChange={setValue}
            data={[
              { label: "English", value: "en" },
              // { label: "Hindi", value: "hi" },
              // { label: "Marathi", value: "mr" },
              // { label: "Gujrati", value: "gu" },
              // { label: "Malayalam", value: "ml" },
              // { label: "Tamil", value: "ta" },
            ]}
            mt={"6px"}
          />
          {data.summary[value]?.audio && (
            <div
              style={{
                width: "25px",
                height: "25px",
                background: playing ? "#228be6" : "#474747",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              {playing ? (
                <PiSpeakerSimpleXFill color="white" onClick={togglePlaying} />
              ) : (
                <PiSpeakerSimpleHighFill
                  color="white"
                  onClick={togglePlaying}
                />
              )}
            </div>
          )}
        </Flex>
        {getSummary(data.summary || "")}
      </Tabs.Panel>
    </Tabs>
  );
};

export default Transcript;
