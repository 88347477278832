import React from "react";
import MyDropzone from "../Dropzone";
import "./styles.css";
import {
  Button,
  Center,
  FileButton,
  Flex,
  Input,
  Modal,
  Text,
} from "@mantine/core";
import { uploadAndTrain } from "../Api/Api";
import { useDisclosure } from "@mantine/hooks";
import UploadVideo from "../UploadVideo";
import ReactPlayer from "react-player";
const Left = () => {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <div>
      <div className="upload">
        <h2>My Videos</h2>
        {/* <p>
          Please provide your own video in order to ask questions about its
          content. You will receive responses in both text format and
          corresponding segments from the video.
        </p> */}

        <div className="videos">
          {JSON.parse(localStorage.getItem("localVideos"))?.map((item) => {
            if (item.video.includes("www.youtube"))
              return (
                <ReactPlayer
                  url={item.video}
                  width={"100%"}
                  height={"auto"}
                  style={{ borderRadius: "10px" }}
                />
              );
            else
              return (
                <>
                  <video
                    onClick={() => {
                      // getInitialTranscripions({
                      //   appId: item.appId,
                      //   video: item.video,
                      // });
                    }}
                    className="thumbnail"
                    src={item.video}
                  ></video>
                </>
              );
          })}
        </div>

        <div style={{ padding: "0px 16px" }}>
          <Button size="lg" mt={"lg"} w={"100%"} onClick={open}>
            Add New Video
          </Button>
          {/* <FileButton
            onChange={(file) => {
              uploadAndTrain(file);
            }}
            accept="video/*"
          >
            {(props) => (
              <Button size="lg" mt={"lg"} w={"100%"} {...props}>
                Add New Video
              </Button>
            )}
          </FileButton> */}
        </div>
      </div>
      <UploadVideo opened={opened} close={close} />
    </div>
  );
};

export default Left;
