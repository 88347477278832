import { Button, Group, rem, Text } from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { BsUpload } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";

import React, { useRef } from "react";
import { uploadAndTrain } from "../Api/Api";

const MyDropzone = () => {
  const openRef = useRef<() => void>(null);

  return (
    <>
      <Dropzone
        onDrop={(files) => {
          uploadAndTrain(files[0]);
        }}
        onReject={(files) => console.log("rejected files", files)}
        openRef={openRef}
        accept={["video/*"]}
      >
        <Group
          mih={"150px"}
          position="center"
          spacing="xl"
          style={{ pointerEvents: "none" }}
        >
          <Dropzone.Accept>
            <BsUpload size={"3.2rem"} color="gray" />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <RxCross2 size={"3.2rem"} color="tomato" />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <BsUpload size={"3.2rem"} color="gray" />
          </Dropzone.Idle>

          <div>
            <Text size="xl" inline>
              Drop your video here or click to select from files
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
              This may take few minutes to upload and process the video.
            </Text>
          </div>
        </Group>
      </Dropzone>
      <Group position="center" mt="md">
        <Button size="md" onClick={() => openRef.current()}>
          Upload Video
        </Button>
      </Group>
    </>
  );
};

export default MyDropzone;
