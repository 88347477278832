import { Button, Container, Drawer, Flex, Group, Input } from "@mantine/core";
import { useEffect, useRef } from "react";
import { BiSearch } from "react-icons/bi";
import "./styles.css";
import { useDisclosure } from "@mantine/hooks";
import Transcript from "../Transcript";
import useGetReduxState from "../useGetReduxState";
import { setResults } from "../store/Dispatchers/App";
import { compareTwoStrings } from "string-similarity";
import { BsFillMicFill } from "react-icons/bs";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import ReactPlayer from "react-player";

const CenterIdle = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const exact = useGetReduxState().data.exactSearch;
  const ref = useRef<HTMLInputElement>();

  const segments = useGetReduxState().data.trainData.transcriptionTime;

  const video = useGetReduxState().data.video;
  const d = useGetReduxState().data;

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const startListening = () =>
    SpeechRecognition.startListening({ continuous: false, lang: "en-IN" });

  useEffect(() => {
    ref.current.value = "";
  }, [video]);

  useEffect(() => {
    let r = { target: { value: ref.current.value } };
    handleSearch(r);
  }, [d.exactSearch]);

  const getResults = (data, key) => {
    if (key.trim() === "") return [];
    if (exact) {
      const results = data.filter((d) =>
        d.Text.toLowerCase().includes(key.toLowerCase())
      );
      return results;
    } else {
      let results = [];
      for (let snippet of data) {
        if (snippet.Text.toLowerCase().includes(key.toLowerCase()))
          results.push(snippet);
        else {
          let wordsOfSnippet = snippet.Text.split(" ");
          let wordsOfKey = key.split(" ");

          for (let word of wordsOfSnippet) {
            let final = 0;
            for (let keyword of wordsOfKey) {
              if (
                compareTwoStrings(word.toLowerCase(), keyword.toLowerCase()) >=
                  0.5 &&
                keyword.toLowerCase()[0] === word.toLowerCase()[0] &&
                Math.abs(word.length - keyword.length) < 3
              )
                final++;
            }
            if (final === wordsOfKey.length) {
              results.push(snippet);
            }
          }
        }
      }
      return results;
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    if (!value) {
      setResults([]);
      return;
    }

    const result = getResults(segments, value);
    setResults([...Array.from(new Set(result))]);
  };

  useEffect(() => {
    if (!listening && transcript.trim()) {
      let e = { target: { value: transcript } };
      handleSearch(e);
      ref.current.value = transcript;
      resetTranscript();
    }
  }, [listening]);

  useEffect(() => {
    let vid = document.getElementById("main-video");
    // @ts-ignore
    vid.pause();
  }, []);
  // {
  //   console.log("hiiiii", video);
  // }
  return (
    <Container style={{ flexGrow: 1, flexShrink: 1 }}>
      {/* {video.includes("www.youtube") ? (
        <ReactPlayer url={video} controls />
      ) : ( */}

      <video
        style={{ width: "100%", borderRadius: "10px" }}
        controls
        autoPlay={true}
        muted
        loop
        src={video}
        id="main-video"
      ></video>
      {/* )} */}

      <div className="query-text">
        <h2 style={{ textAlign: "center" }}>
          All set! You can search for your query now.
        </h2>
        <p style={{ textAlign: "center" }}>
          We will show you a list of best suitable results
        </p>
      </div>
      <Flex justify={"center"} align={"center"} columnGap={"md"} w={"100%"}>
        <Input
          icon={<BiSearch />}
          variant="filled"
          size={window.innerWidth < 600 ? "md" : "lg"}
          placeholder="Search your query here..."
          onChange={handleSearch}
          ref={ref}
          w={"100%"}
        />
        <BsFillMicFill
          size={listening ? "2.5rem" : "2rem"}
          color={listening ? "#4CAF50" : "#238be6"}
          style={{ cursor: "pointer" }}
          onClick={startListening}
        />
      </Flex>

      <h3 style={{ textAlign: "center", marginTop: "10px" }}>OR</h3>

      <Group position="center" mt="md">
        <Button
          size={window.innerWidth < 600 ? "sm" : "lg"}
          onClick={open}
          style={{ borderRadius: "4px" }}
          mb={"xl"}
        >
          View Transcript
        </Button>
      </Group>

      <Drawer
        opened={opened}
        onClose={close}
        title={<h3>Video Transcription</h3>}
        size={"lg"}
      >
        <Transcript close={close} />
      </Drawer>
    </Container>
  );
};

export default CenterIdle;
