import React, { useEffect, useRef } from "react";
import MyDropzone from "./Dropzone";

import "./App.css";
import {
  Button,
  Center,
  Container,
  Drawer,
  Flex,
  Input,
  LoadingOverlay,
  Switch,
  Text,
} from "@mantine/core";
import Left from "./Left";
import Right from "./Right";
import CenterIdle from "./Center/CenterIdle";
import useGetReduxState from "./useGetReduxState";
import { uploadAndTrain } from "./Api/Api";
import { toggleExact } from "./store/Dispatchers/App";
import { useDisclosure } from "@mantine/hooks";
import UploadVideo from "./UploadVideo";
import ReactPlayer from "react-player";

const App2 = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [openedModal, { open: openModal, close: closeModal }] =
    useDisclosure(false);
  useEffect(() => {
    let t = {
      appId: "7ac2bfb9-a424-406d-b37e-a488aa83d7d7",
      video:
        "https://storage.googleapis.com/corover-prod-bucket/loksabha/Copy%20of%2009-02-2023%20LS-01.mp4",
      name: "",
    };

    let u = {
      appId: "e8cadcce-b54f-4a21-a61f-2816444e2c26",
      video:
        "https://storage.googleapis.com/corover-prod-bucket/loksabha/PM Modi's Mann Ki Baat with the Nation, June 2023.mp4",
    };

    let v = {
      appId: "24e90317-1db5-480b-965f-c164de0489cf",
      video:
        "https://storage.googleapis.com/corover-prod-bucket/loksabha/à¤¸à¥\u0080à¤\u0096à¥\u008b ye Customer acquisition ka à¤¨à¤¯à¤¾ model  Shark Tank India  Recode  Season 2  Full Pitch.mp4",
    };

    let w = {
      appId: "27f0c388-9e7b-4264-816d-25298f62c34a",
      video:
        "https://storage.googleapis.com/corover-prod-bucket/loksabha/Mr. N. P. Singh talks about Sony Pictures Networks India.mp4",
    };

    if (!localStorage.getItem("localVideos")) {
      // localStorage.setItem("localVideos", JSON.stringify([t, u, v, w]));
    }
    // getInitialTranscripions(t);

    // getSummary("PM modi inaugrated ")
  }, []);
  const loader = useGetReduxState().data.loader;
  const exact = useGetReduxState().data.exactSearch;

  return (
    <>
      <div className="main" style={{ position: "relative" }}>
        <div className="header">
          <Flex
            justify={"space-between"}
            align={"center"}
            className="container"
          >
            <img
              src="https://uiresource.ap-south-1.linodeobjects.com/corover-v245/images/logo-white.png"
              alt=""
              style={{ width: "120px" }}
            />
            <Button onClick={open}>My Videos</Button>
          </Flex>
        </div>
        <Flex
          p={"30px"}
          justify={"space-between"}
          align={"flex-start"}
          columnGap={"lg"}
          // direction={"column"}
          className="main-container"
        >
          <Left />
          <div>
            <CenterIdle />
          </div>
          <Right />
        </Flex>
        <Drawer
          position="right"
          opened={opened}
          onClose={close}
          title={<h3>Uploaded Videos</h3>}
        >
          <Button
            size="lg"
            mt={"lg"}
            w={"100%"}
            onClick={() => {
              close();
              openModal();
            }}
          >
            Add New Video
          </Button>
          {JSON.parse(localStorage.getItem("localVideos"))?.map((item) => {
            // if (item.video.includes("www.youtube"))
            //   return (
            //     <ReactPlayer url={item.video} width={"100%"} height={"auto"} />
            //   );
            // else
            return (
              <video
                onClick={() => {
                  // getInitialTranscripions({
                  //   appId: item.appId,
                  //   video: item.video,
                  // });
                  close();
                }}
                className="thumbnail"
                src={item.video}
              ></video>
            );
          })}
        </Drawer>
      </div>
      <div className="footer">
        <Flex justify={"center"} columnGap={"sm"} align={"center"}>
          <Text>Powered by</Text>
          <a
            href="https://corover.ai"
            style={{ display: "block", lineHeight: 0 }}
          >
            <img
              src="https://uiresource.ap-south-1.linodeobjects.com/corover-v245/images/logo-white.png"
              alt=""
              style={{ width: "80px" }}
            />
          </a>
        </Flex>
      </div>
      <UploadVideo close={closeModal} opened={openedModal} />
    </>
  );
};

export default App2;
