const intialData = {
  currentTimeStamp: {
    start: null,
    end: null,
  },
  trainData: {
    transcriptionFreeEn: "",
    transcriptionFreeHi: "",
    transcriptionTime: [],
    appId: "",
    summary: "",
  },
  video: "",
  searchResults: [],
  loader: false,
  exactSearch: false,
  error: "",
};

const reducer = (data = intialData, action) => {
  switch (action.type) {
    case "SET_TIMESTAMP":
      return {
        ...data,
        currentTimeStamp: {
          start: action.start,
          end: action.end,
        },
      };
    case "SET_TRAIN_DATA":
      return {
        ...data,
        trainData: {
          transcriptionFreeEn: action.transcriptionFreeEn,
          transcriptionFreeHi: action.transcriptionFreeHi,
          transcriptionTime: action.transcriptionTime,
          appId: action.appId,
          summary: action.summary,
        },
      };
    case "SET_VIDEO":
      return { ...data, video: action.video };
    case "SET_SEARCH_RESULTS":
      return { ...data, searchResults: action.searchResults };
    case "SET_LOADER":
      return { ...data, loader: action.value };
    case "SET_EXACT":
      return { ...data, exactSearch: action.value };
    case "SET_ERROR":
      return { ...data, error: action.value };
    default:
      return data;
  }
};

export default reducer;
