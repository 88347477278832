import React from "react";
import { BiSearch } from "react-icons/bi";
import "./styles.css";
import { Button, Center, Flex, Group, Switch } from "@mantine/core";
import { setTimeStamp, toggleExact } from "../store/Dispatchers/App";
import useGetReduxState from "../useGetReduxState";
import moment from "moment";

const ListItem = ({ start, end, transcript }) => {
  return (
    <div className="list-item">
      <p>
        <span onClick={() => setTimeStamp(start, end)}>{start}</span>
        {transcript}
      </p>
    </div>
  );
};

const SearchItem = () => {
  return (
    <div className="search-item">
      <BiSearch size={"30px"} />
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
    </div>
  );
};

const Right = () => {
  const results = useGetReduxState().data.searchResults;
  const exact = useGetReduxState().data.exactSearch;
  return (
    <div className="search-results-container">
      <div className="search-results">
        <Flex
          justify={"space-between"}
          columnGap={"lg"}
          align={"center"}
          mb={"20px"}
        >
          <h2>Search Results</h2>
          <Switch
            size="lg"
            onLabel={<h3>Exact Match</h3>}
            offLabel={<h3>Nearest Match</h3>}
            checked={exact}
            onChange={(event) => toggleExact()}
          />
        </Flex>

        {results.map((result) => (
          <ListItem
            start={result.Start}
            end={result.End}
            transcript={result.Text}
          />
        ))}

        {results.length < 1 && <p className="not-found">No Results</p>}
      </div>

      {/* <div className="search-results" style={{ marginTop: "40px" }}>
        <h2>Search History</h2>

        <SearchItem />
        <SearchItem />
        <SearchItem />
        <SearchItem />
      </div> */}
    </div>
  );
};

export default Right;
