import store from "..";
import * as actions from "../Actions/App";

export const setResults = (results) => {
  store.dispatch(actions.setSearchResults(results));
};

export const setVideo = (value) => {
  store.dispatch(actions.setVideo(value));
  setTimeout(() => {
    let vid = document.getElementById("main-video");
    // @ts-ignore
    vid.pause();
  }, 100);
};

const parseTime = (time) => {
  return time;
  // return parseFloat(time) / 1000}`);
};

export const setTrainData = ({
  appId,
  freeHi,
  time,
  freeEn,
  summary,
  speaker,
}) => {
  store.dispatch(
    actions.setTrainData({
      transcriptionFreeEn: freeEn,
      transcriptionFreeHi: freeHi,
      transcriptionTime: time.map((obj) => ({
        ...obj,
        startTime: parseTime(obj.Start),
        endTime: parseTime(obj.End),
      })),
      appId,
      summary,
      speaker,
    })
  );
};

export const setTimeStamp = (start, end) => {
  console.log("RTTTTT", start, end);
  // let audioPlayer: any = document.getElementById("myaudio") as HTMLAudioElement;
  // audioPlayer.src = url;
  // audioPlayer.play();
  let video = store.getState().data.video;
  if (end === -1) video = video.split("#t")[0] + `#t=${start}`;
  else video = video.split("#t")[0] + `#t=${start},${end}`;
  setVideo(video);
  store.dispatch(actions.setTimeStamp({ start, end }));

  const vid = document.getElementById("main-video");
  // @ts-ignore
  vid.muted = false;

  setTimeout(() => {
    // @ts-ignore
    vid.play();
  }, 200);
};

export const toggleLoader = () => {
  const t = store.getState().data.loader;
  store.dispatch(actions.setLoader(!t));
};

export const toggleExact = () => {
  const t = store.getState().data.exactSearch;
  store.dispatch(actions.setExact(!t));
};

export const setError = (value) => {
  store.dispatch(actions.setError(value));
};
