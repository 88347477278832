import {
  Button,
  Center,
  Flex,
  Input,
  LoadingOverlay,
  Modal,
  Text,
} from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import MyDropzone from "./Dropzone";
import { uploadAndTrainYoutube, preLoadVideo } from "./Api/Api";
import useGetReduxState from "./useGetReduxState";
import { setError } from "./store/Dispatchers/App";

const UploadVideo = ({ opened, close }) => {
  const ref = useRef<HTMLInputElement>();
  const loader = useGetReduxState().data.loader;
  const error = useGetReduxState().data.error;
  const [sent, isSent] = useState(false);

  useEffect(() => {
    preLoadVideo();
    if (loader && !sent) isSent(true);
    else if (!loader && sent && !error) {
      close();
    }
  }, [loader]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (ref.current.value) uploadAndTrainYoutube(ref.current.value);
    }
  };

  return (
    <Modal
      size={"lg"}
      opened={opened}
      onClose={close}
      title="Add new Video"
      centered
      closeOnClickOutside={false}
      closeOnEscape={false}
      withCloseButton={!loader}
    >
      {error && (
        <div>
          <h2 style={{ marginBottom: "16px" }}>{error}</h2>
          <Button
            onClick={() => {
              setError("");
              close();
            }}
          >
            Okay
          </Button>
        </div>
      )}

      <LoadingOverlay
        visible={loader}
        overlayBlur={2}
        loaderProps={{ size: "xl" }}
      />
      {/* Modal content */}

      {/* <Text>
    Please provide your own video in order to search its content. You will
    receive responses in both text format and corresponding segments from
    the video.
  </Text> */}
      <Flex w={"100%"} align={"flex-end"} columnGap={"md"}>
        {/* <Input.Wrapper
          id="input-demo"
          label="Youtube URL"
          w={"100%"}
          description="Please note that the youtube video should not have a copyright/privacy policy"
        > */}
        {/* <Input
            id="input-demo"
            placeholder="Please provide youtube link for the video..."
            ref={ref}
            onKeyDown={handleKeyDown}
          /> */}
        {/* </Input.Wrapper> */}
        {/* <Button
          onClick={() => {
            if (ref.current.value) uploadAndTrainYoutube(ref.current.value);
          }}
        >
          Submit
        </Button> */}
      </Flex>

      <Center my={"lg"}>{/* <Text>OR</Text> */}</Center>
      <MyDropzone />
    </Modal>
  );
};

export default UploadVideo;
