import { combineReducers } from "redux";

import data from "./App";
// import train from "./train";
// import journey from "./journey";
// import faqs from "./faqs";
// import app from "./app";
// import user from "./user";
// import passengerform from "./passengerForm";
// import utils from "./utils";

export const rootReducer = combineReducers({
  data,
  // train,
  // journey,
  // faqs,
  // app,
  // passengerform,
  // user,
  // utils,
});

export type RootState = ReturnType<typeof rootReducer>;
