export const setTimeStamp = ({ start, end }) => {
  const action = { type: "SET_TIMESTAMP", start, end };
  return action;
};

export const setTrainData = ({
  transcriptionFreeEn,
  transcriptionFreeHi,
  transcriptionTime,
  appId,
  summary,
  speaker
}) => {
  const action = {
    type: "SET_TRAIN_DATA",
    transcriptionFreeEn,
    transcriptionFreeHi,
    transcriptionTime,
    appId,
    summary,
    speaker
  };
  return action;
};

export const setVideo = (video) => {
  const action = { type: "SET_VIDEO", video };
  return action;
};

export const setSearchResults = (results) => {
  const action = { type: "SET_SEARCH_RESULTS", searchResults: results };
  return action;
};

export const setLoader = (value) => {
  const action = { type: "SET_LOADER", value };
  return action;
};

export const setExact = (value) => {
  const action = { type: "SET_EXACT", value };
  return action;
};

export const setError = (value) => {
  const action = { type: "SET_ERROR", value };
  return action;
};
